import { apiMethods } from '../infrastructure/api/index';

export const useSendMessage = () => {
	const getSendMessage = apiMethods.useSendMessage();

	return async (payload: any) => {
		const result = await getSendMessage(payload);
		if (result.isRight()) {
			console.log(result.value);
		}
	};
};

import type { HelpForm } from '../../types';
import type { Response } from '~/src/shared/lib';
import { useApi } from '@/src/services/network';

type ApiResult = Response<string>;

const fromDomain = (payload: HelpForm): any => {
	return {
		name: payload.name,
		email: payload.email,
		text: payload.message,

	};
};

export const useSendMessage = () => {
	const api = useApi();

	return async (payload: HelpForm) => {
		return (
			await api<ApiResult>('/Support/sendMessageTrader/', {
				method: 'POST',
				body: fromDomain(payload),
			})
		).mapRight(res => res.result);
	};
};
